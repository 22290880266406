import React from 'react';
import loadable from '@loadable/component';
import {Link, graphql} from 'gatsby';
import {ChevronLeft} from 'react-feather';

const Content = loadable(() => import('../components/Content'));
const Layout = loadable(() => import('../components/Layout'));
import './SinglePost.scss';
import NavLink from '../components/NavLink';

export const SinglePostTemplate = ({
                                       title,
                                       date,
                                       featuredImage,
                                       author,
                                       body
                                   }) => (
    <main>
        <article
            className="SinglePost section light"
            itemScope
            itemType="http://schema.org/BlogPosting"
        >
            <div className="container skinny pb-8">
                <Link className="SinglePost--BackButton text-primary mb-4" to="/actualites/">
                    <ChevronLeft/> RETOUR
                </Link>

                <div className="SinglePost--Meta">
                    {date && (
                        <time
                            className="SinglePost--Meta--Date"
                            itemProp="dateCreated pubdate datePublished"
                            date={date}
                        >
                            {date}
                        </time>
                    )}

                    {author && (
                        <span className="SinglePost--Meta--Category"> | <Link to={'/' + author.slug}>{author.firstname} {author.name}</Link></span>
                    )}
                </div>

                {title && (
                    <h1 className="SinglePost--Title text-primary text-6xl font-bold p-4" itemProp="title">
                        {title}
                    </h1>
                )}

                <div className="SinglePost--Content relative">


                    <img className="SinglePost--Image" src={featuredImage} alt={title}/>


                    <div className="SinglePost--InnerContent">
                        <Content source={body}/>

                        <hr className="mt-8 mb-8 w-full"/>

                        {author && (
                            <div className="SinglePost-Img-Author">
                                <img src={author.featuredImage} alt={author.name}/>
                                <div className="">
                                    <Link className="font-bold SinglePost-Img-Author-Text" to={'/' + author.slug}>{author.firstname} {author.name}</Link>
                                    {date && (
                                        <time
                                            className="SinglePost--Meta--Date"
                                            itemProp="dateCreated pubdate datePublished"
                                            date={date}
                                        >
                                            {date}
                                        </time>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>


                </div>
            </div>
        </article>
    </main>
);

// Export Default SinglePost for front-end
const SinglePost = ({data: {post, allMembres}}) => {
    const author = allMembres.edges.find(edge => edge.node.frontmatter.slug === post.frontmatter.author);
    return (
        <Layout
            meta={post.frontmatter.meta || false}
            title={post.frontmatter.title || false}
        >
            <SinglePostTemplate
                {...post}
                {...post.frontmatter}
                author={author.node.frontmatter}
                body={post.html}
            />
        </Layout>
    )
};

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        featuredImage
        author
        date(formatString: "DD MMMM YYYY", locale: "fr")
        categories {
          category
        }
      }
    }
    allMembres: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "membres" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            name
            firstname
            job
            slug
            featuredImage
          }
        }
      }
    }
  }
`;
